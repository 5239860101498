import { toast } from 'react-toastify';
global.API_ADDRESS = 'https://api.b2b.dashandel.cz';
global.locale = 'cz';
global.translations = {
    sk: {},
    cz: {},
};
const checkIfEmpty = (variable) => {
    if (null == variable || undefined == variable) return true;
    if (Array.isArray(variable) && variable.length < 1) return true;
    return typeof variable === 'object' && Object.keys(variable).length < 1;
};
global.empty = checkIfEmpty;
const init = async () => {
    const response = await fetch(`${global.API_ADDRESS}/api/dictionary`);
    const json = await response.json();
    global.getUser(); // to set locale
    global.translations = json;
};

global.nf = (num, appendText = '') => {
    if (undefined === num) return 0;
    let parsed = parseFloat(num);
    if (parsed < 1) return 0;
    num = Math.round(num);
    let stringed = num + '';
    let arred = stringed.split('');
    let result = num;
    if (parsed >= 1000 && parsed < 10000) {
        arred.splice(1, 0, ' ');
        result = arred.join('');
    } else if (parsed >= 10000 && parsed < 100000) {
        arred.splice(2, 0, ' ');
        result = arred.join('');
    } else if (parsed >= 100000 && parsed < 1000000) {
        arred.splice(3, 0, ' ');
        result = arred.join('');
    } else if (parsed >= 100000 && parsed < 100000000) {
        arred.splice(1, 0, ' ');
        arred.splice(5, 0, ' ');
        result = arred.join('');
    } else {
        result = num;
    }
    return result;
};
global.init = init;

const translate = (string) => {
    if (!global.translations[global.locale][string]) {
        fetch(`${global.API_ADDRESS}/api/dictionary`, {
            method: 'POST',
            body: JSON.stringify({ string, locale: global.locale }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return string + '____';
    }
    return global.translations[global.locale][string];
    console.log(string);
};

global._e = translate;
const isMobile = () => {
    return window.innerWidth <= 1360;
};
global.isMobile = isMobile;
// init();
// import React from 'react';
// import { AsyncStorage , Text , Alert , Dimensions } from 'react-native';
// import moment from 'moment';
// import * as siderNavigation from '@app/contexts/sider_nav';
// import registerForPushNotificationsAsync from 'app/actions/Push/registerForPushNotificationsAsync';
// import NavigationService from '@app/navigation/Root';

// global.log = ( ...params ) => {
// 	// params.forEach( ( val , k ) => {
// 	// 	alert( val );
// 	// });
// 	return true;
// }

// global.handlePush = () => {
// 	registerForPushNotificationsAsync();
// }

// const ruShortDays = [
//   'Вс',
//   'Пн',
//   'Вт',
//   'Ср',
//   'Чт',
//   'Пт',
//   'Сб',
// ];

// global.cleanCart = async() => {
// 	await AsyncStorage.removeItem( 'cart.seats' );
//     await AsyncStorage.removeItem( 'cart.subscriptions' );
// 	await AsyncStorage.setItem( 'cartNumber' , "0" );
// 	global.cartNumber = 0;
// 	if ( undefined !== global.cartt && null !== global.cartt ) {
// 		global.cartt.setState({ number: 0 });
// 	}
// }
// global.logOut = async() => {
//   	this.isAuth = false;
// 	await AsyncStorage.clear();
// 	await global.setCartNumber();
// }
// global.setCartNumber = async () => {
// 	let seats = await AsyncStorage.getItem( 'cart.seats' ) ?? "{}";
// 	seats = JSON.parse( seats );
// 	let count = Object.keys( seats ).length;
// 	let subscriptions = await AsyncStorage.getItem( 'cart.subscriptions' ) ?? "{}";
// 	subscriptions = JSON.parse( subscriptions );
// 	count += Object.keys( subscriptions ).length;
// 	// console.log({caaa:global.cartNumber})
// 	global.cartNumber = count;
// 	if ( undefined !== global.cartt && null !== global.cartt ) {
// 		global.cartt.setState({ number: count });
// 	}
// 	await AsyncStorage.setItem( 'cartNumber' , count + "" );
// }

// global.getCartNumber = async () => {
// 	let count = await AsyncStorage.getItem( 'cartNumber' );
// 	if ( null == count ) count = 0;
// 	// console.log({cartCount:count})
// 	return count;
// }

// global.smallSize = () => {
// 	let width = Dimensions.get( 'window' ).width;
// 	// console.log({width})
// 	return width <= 350;
// }

// global.getWeekDay = ( date ) => {
// 	date = moment( date ).day();
// 	return ruShortDays[ date ];
// }

global.handleError = (error) => {
    // if ( undefined !== error.response && undefined !== error.response.data ) {
    //   console.log({ errrarrraaaa: error.response.data });
    // }
    // console.log({ handleError: error});
    let errorTexts;
    if (error.response.status == 302) {
        // this.props.navigation.navigate( "Auth.ConfirmEmail1Reg" , { phone: this.state.phone, action: 'register' } );
    } else if (error.response.status == 401) {
        localStorage.clear();
        window.location = '/login';
        // errorTexts = generateErrors( { 'key': [ error.response.data.error ] } );
        // global.isAuth = false;
        // await AsyncStorage.clear();
        // NavigationService.navigate('Auth.Base' );
    } else if (error.response.status == 425) {
        // errorTexts = generateErrors( { 'key': [ error.response.data.error ] } );
        // global.isAuth = false;
        // await AsyncStorage.clear();
        // NavigationService.navigate('Auth.Base' );
    } else if (error.response.status == 426) {
        // errorTexts = generateErrors( { 'key': [ error.response.data.error ] } );
        // NavigationService.navigate( 'Profile.Cards' );
    } else {
        try {
            if (undefined !== error?.response?.data?.error) {
                generateErrors({
                    key: [error.response.data.error],
                });
            } else {
                generateErrors(error.response.data.errors);
            }
        } catch (e) {
            console.log('Error', e);
        }
    }
};

// global.Alert = ( title , type = 'error' ) => {
// 	let heading = 'Ошибка!';
// 	if ( type !== 'error' ) {
// 		heading = '';
// 	}
//     Alert.alert(
//       heading,
//       title,
//       [
//         { text: 'OK' },
//       ]
//     );
// }
function generateErrors(errors) {
    let errTextArr = [];
    for (let key in errors) {
        let err = errors[key][0];
        global.Alert(err);
    }
}

// global.getToken = async () => {
//     try {
// 		let token = await AsyncStorage.getItem( 'token' );
// 		if ( undefined == token || null == 'token' || token.length < 10 ) {
// 			return false;
// 		}
// 		return token;
//     } catch ( e ) {
//       	return false;
//     }
// }
// global.authenticated = async () => {
//     try {
// 		let token = await AsyncStorage.getItem( 'token' );
// 		if ( undefined == token || null == 'token' || token.length < 10 ) {
// 			return false;
// 		}
// 		return true;
//     } catch ( e ) {
//       	return false;
//     }
// }

// COMMON

global.Alert = (string, type = 'error') => {
    if (type !== 'error') {
        toast.success(string);
        return;
    }
    toast.error(string, {
        position: toast.POSITION.BOTTOM_RIGHT,
    });
    // alert( string );
};

global.validateField = (value, minLength = 1, maxLength = 100) => {
    if (
        null == value ||
        value == undefined ||
        value.length < minLength ||
        value.length > maxLength ||
        value == 'error'
    ) {
        return false;
    }
    return true;
};

global.validateEmailField = (value) => {
    if (!global.validateField(value, 5, 40)) return false;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
};

global.validateBooleanField = (value) => {
    if (!global.validateField(value)) return false;
    if (value === 1 || value === 0 || value === '1' || value === '0')
        return true;
    return false;
};

global.validatePhoneField = (value) => {
    if (!global.validateField(value, 3, 20)) return false;
    const re = /^(?=.*[0-9])[- +0-9]+$/;
    return re.test(String(value).toLowerCase());
};

global.getToken = () => {
    let user = global.getUser();
    let token = user?.token;
    if (token) {
        return token;
    }
    return false;
};
global.getUser = () => {
    let user = localStorage.getItem('user');
    if (null == user || undefined == user || 'undefined' == user) {
        // global.Alert(
        //     'There was an error with authorization, please clear browser cache before continuing or use another browser.'
        // );
        return null;
    } else {
        user = JSON.parse(user);
        global.locale = user.lang == 0 ? 'cz' : 'sk';
    }
    return user;
};

global.getHeader = async () => {
    let token = global.getToken();
    if (false == token) {
        // global.Alert(
        //     'There was an error with authorization, please clear browser cache before continuing or use another browser.'
        // );
        return {};
    }
    return {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
};

// COMMON
//
// // STEP 1
//
// global.getStep1 = () => {
//     let step1 = localStorage.getItem('step_1');
//     if (
//         null !== step1 &&
//         undefined !== step1 &&
//         typeof step1 == 'string' &&
//         step1.length > 5
//     ) {
//         let resp = JSON.parse(step1);
//         return resp;
//     }
//     return false;
// };
//
// global.setStep1 = (data) => {
//     if (
//         data.paymentMethod == undefined ||
//         data.deliveryMethod == undefined ||
//         data.dropShipping == undefined
//     ) {
//         global.Alert('Error storing Step 1 data');
//         return false;
//     }
//     let step1 = localStorage.setItem('step_1', JSON.stringify(data));
//     localStorage.setItem('dropShipping', data.dropShipping);
//     localStorage.removeItem('step_2');
//     localStorage.removeItem('step_3');
//     return true;
// };
//
// // STEP 1
//
// // STEP 2
//
// global.getCanStep2 = () => {
//     let step1 = global.getStep1();
//     if (false !== step1) {
//         return true;
//     }
//     return false;
// };
//
// global.getDropShipping = () => {
//     let dropShipping = localStorage.getItem('dropShipping');
//     if (
//         null !== dropShipping &&
//         undefined !== dropShipping &&
//         typeof dropShipping == 'string' &&
//         dropShipping.length > 0
//     ) {
//         return dropShipping == 1 ? true : false;
//     }
//     return false;
// };
//
// global.getStep2 = () => {
//     let step2 = localStorage.getItem('step_2');
//     if (
//         null !== step2 &&
//         undefined !== step2 &&
//         typeof step2 == 'string' &&
//         step2.length > 2
//     ) {
//         let resp = JSON.parse(step2);
//         return resp;
//     }
//     return false;
// };
//
// global.setStep2 = (data) => {
//     let step2 = global.getStep2();
//     let newStep2 = {};
//     if (false !== step2) {
//         if (undefined !== data.clientAddress) {
//             newStep2 = {
//                 clientAddress: data.clientAddress || {},
//                 userAddress: step2.userAddress || {},
//             };
//         } else {
//             newStep2 = {
//                 clientAddress: step2.clientAddress || {},
//                 userAddress: data.userAddress || {},
//             };
//         }
//     } else {
//         newStep2 = {
//             clientAddress: data.clientAddress || {},
//             userAddress: data.userAddress || {},
//         };
//     }
//     localStorage.setItem('step_2', JSON.stringify(newStep2));
//     return true;
// };
//
// // STEP 2
//
// // STEP 3
// global.getCanStep3 = () => {
//     let step2 = global.getStep2();
//     if (false !== step2) {
//         if (
//             +step2?.userAddress > 0 &&
//             (step2?.clientAddress?.client_name?.length > 0 ||
//                 +step2?.clientAddress > 0)
//         ) {
//             return true;
//         } else {
//             return false;
//         }
//     }
//     return false;
// };
//
// global.getProducts = () => {
//     let products = localStorage.getItem('total');
//     if (products === null || products === undefined || products.length < 2) {
//         products =
//             '{"10071241908":[1407,"1",1618.05,"1"],"10071241905":[1407,"1",1618.05,"1"],"10010080002":[378,"1",457.38,"1"],"10010060003":[2364,"6",2860.44,"6"],"10010110002":[442,"1",534.82,"1"]}';
//         // products = '{"10070990003":[281,"1",323.15,"1"],"20810064301":[666,"2",805.86,"2"],"ALP201":[646,"1",781.66,"1"]}';
//     }
//     let resp = JSON.parse(products);
//     return resp;
// };
//
// global.setStepThreeComment = (value) => {
//     localStorage.setItem('step_3_comment', value);
// };
// global.getStepThreeComment = () => {
//     let res = localStorage.getItem('step_3_comment');
//     if (res === null || res === undefined || res.length < 1) {
//         return '';
//     }
//     return res;
// };
//
// global.getFinalData = () => {
//     // MAYBE SOME VALIDATION BEFORE
//     // let res = JSON.stringify( localStorage );
//     let res = localStorage;
//     return res;
// };
// //STEP 3
